<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-xxl-3">
        <Customers :totalCustomers = totalCustomers></Customers>
        <Orders :totalOrders = totalOrders></Orders>
        <Assigneds :totalAssigneds = totalAssigneds></Assigneds>
        <MissedJob :totalMissed = totalMissed></MissedJob>
      </div>

      <div class="col-xxl-3">
        <IndependentContractor :totalContractors = totalContractors></IndependentContractor>
        <PendingOrders :totalPending = totalPending></PendingOrders>
        <Inspection :totalDone = totalDone></Inspection>
        <JobsRescheduled :totalReschedule = totalReschedule></JobsRescheduled>
      </div>

      <div class="col-xxl-3">
        <NewHomes :newHomes = newHomes></NewHomes>
        <IndividualJobOffer :individual = individual></IndividualJobOffer>
        <InspectionDone :totalConfirm = totalConfirm></InspectionDone>
        <Refunded :totalRefunded = totalRefunded></Refunded>
        
      </div>
      <div class="col-xxl-3">
        <NewOffices :newOffices = newOffices></NewOffices>
        <Available :totalAvailables = totalAvailables></Available>
        <JobsPaid :totalPaid = totalPaid></JobsPaid>
        <Canceled :totalCanceled = totalCanceled></Canceled>
      </div>
    </div>
    <!--end::Dashboard--> 
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Customers from "@/view/content/widgets/stats/Customers.vue";
import Orders from "@/view/content/widgets/stats/Orders.vue";
import PendingOrders from "@/view/content/widgets/stats/PendingOrders.vue";
import IndependentContractor from "@/view/content/widgets/stats/IndependentContractor.vue";
import Assigneds from "@/view/content/widgets/stats/Assigneds.vue";
import Available from "@/view/content/widgets/stats/Available.vue";
import Inspection from "@/view/content/widgets/stats/Inspection.vue";
import IndividualJobOffer from "@/view/content/widgets/stats/IndividualJobOffer.vue";
import InspectionDone from "@/view/content/widgets/stats/InspectionDone.vue";
import MissedJob from "@/view/content/widgets/stats/MissedJob.vue";
import JobsRescheduled from "@/view/content/widgets/stats/JobsRescheduled.vue";
import JobsPaid from "@/view/content/widgets/stats/JobsPaid.vue";
import NewHomes from "@/view/content/widgets/stats/NewHomes.vue";
import NewOffices from "@/view/content/widgets/stats/NewOffices.vue";
import Refunded from "@/view/content/widgets/stats/Refunded.vue";
import Canceled from "@/view/content/widgets/stats/Canceled.vue";

export default {
  name: "dashboard",
  components: {
    Customers,
    Orders,
    PendingOrders,
    IndependentContractor,
    Assigneds,
    Available,
    Inspection,
    IndividualJobOffer,
    InspectionDone,
    MissedJob,
    JobsRescheduled,
    JobsPaid,
    NewHomes,
    NewOffices,
    Refunded,
    Canceled
  },
  data() {
    return {
      totalCustomers: 0,
      totalOrders: 0,
      totalContractors: 0,
      totalAssigneds: 0,
      totalAvailables: 0,
      newWorkers: 0,
      totalPending: 0,
      totalDone: 0,
      totalConfirm: 0,
      totalMissed: 0,
      totalReschedule: 0,
      totalPaid: 0,
      individual: 0,
      array: 0,
      newHomes: 0,
      newOffices: 0,
      totalRefunded: 0,
      totalCanceled: 0,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
 
    var auth = localStorage.getItem('id');
    if(auth != null && auth != 'undefined'){
        console.log('existe')
    }else{
        console.log('nao existe')
        this.$router.push({ name: "login" });
    }

    fetch('https://cleancity.krueltech.com/dev/api/api.php?action=readTotalsAdmin')
      .then(res => res.json())
      .then((data) => {
        this.totalCustomers = data.customers
        this.totalOrders = data.orders
        this.totalOrders = data.orders ,
        this.totalContractors = data.contractors ,
        this.totalAssigneds = data.assigneds ,
        this.totalAvailables = data.availables ,
        this.newWorkers = data.newWorker ,
        this.totalPending = data.pending ,
        this.totalDone = data.done ,
        this.totalConfirm = data.confirm ,
        this.totalMissed = data.missed ,
        this.totalReschedule = data.reschedule ,
        this.totalPaid = data.paid ,
        this.individual = data.individual ,
        this.array = data.Array ,
        this.newHomes = data.newHomes,
        this.newOffices = data.newOffices

        console.log(data.reschedule)

      }).catch(console.log)
  },
  methods: {

    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
